import { h } from 'preact'
import { Router } from 'preact-router'
import { Globals } from '@react-spring/web'
import Header from './header'
import SSRProvider from 'react-bootstrap/SSRProvider'
import useReducedMotion from '/components/utils/useReducedMotion.js'
// Code-splitting is automated for `routes` directory
import Home from '../routes/home'
import Profile from '../routes/concepts'
import About from '../routes/about'
import { useEffect, useState } from 'preact/hooks'

const App = () => {
  const prefersReducedMotion = useReducedMotion()
  const [newVisitor, setNewVisitor] = useState(false)
  useEffect(() => {
    setNewVisitor(true)
  })
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion])
  return (
    <SSRProvider>
      <div id='app'>
        <Header />
        <Router>
          <Home path='/' newVisitor={newVisitor} />
          <Profile path='/concepts/' />
          <About path='/about/' />
        </Router>
      </div>
    </SSRProvider>
  )
}

export default App

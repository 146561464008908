import { h } from 'preact'
import { Link } from 'preact-router/match'
import style from './style.scss'

const Header = () => (
  <header class={style.header}>
    <nav>
      <Link activeClassName={style.active} href='/'>
        Home
      </Link>
      <Link activeClassName={style.active} href='/concepts'>
        Concepts
      </Link>
      <Link activeClassName={style.active} href='/about'>
        About
      </Link>
    </nav>
  </header>
)

export default Header
